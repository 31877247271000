@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'fonts.scss';

body {
  font-family: Mulish, 'Helvetica Neue', sans-serif;
}

h1 {
  @apply text-4xl md:text-5xl lg:text-6xl font-extrabold leading-none tracking-tight;
}

h2 {
  @apply text-2xl md:text-3xl lg:text-4xl font-extrabold mb-4 mt-8 md:mb-6 md:mt-10 lg:mb-8 lg:mt-12;
}

h3 {
  @apply text-xl md:text-2xl lg:text-3xl font-bold mb-2 mt-4 md:mb-3 md:mt-5 lg:mb-4 lg:mt-6;
}

a {
  @apply text-werkstatt-green-500 hover:underline hover:underline-offset-2 hover:text-gray-800 transition-all;
}

p {
  @apply my-2;
}

ul {
  @apply list-disc pl-8;
}

li {
  @apply mb-2;
}

.modal-open {
  overflow: hidden;
}

.label {
  @apply block text-sm font-bold mb-2;
}

.form-input {
  @apply w-full p-2 border rounded focus:ring-werkstatt-green-500 focus:border-werkstatt-green-500;
}

.form-checkbox {
  @apply w-5 h-5 text-werkstatt-green-500 bg-gray-100 border-gray-300 rounded checked:bg-werkstatt-green-500 focus:ring-werkstatt-green-500 focus:ring-2;
}

.error {
  @apply text-red-500 text-xs mt-2;
}

.button {
  @apply w-full bg-werkstatt-green-500 hover:bg-gray-500 disabled:cursor-not-allowed disabled:bg-gray-400 text-white p-2 rounded transition-all;
}
